import CryptoJS from 'crypto-js'

//MÃ HÓA MẠNH HƠN Với key và chữ ký số
// Hàm mã hóa dữ liệu
export function encryptData(data, secretKey) {
  const key = CryptoJS.enc.Hex.parse(secretKey) // Khóa 256-bit
  const iv = CryptoJS.lib.WordArray.random(16) // IV 128-bit ngẫu nhiên

  // Mã hóa dữ liệu
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  // Tạo HMAC để đảm bảo tính toàn vẹn
  const hmac = CryptoJS.HmacSHA256(encrypted.toString(), key).toString()

  return {
    encryptedData: encrypted.toString(),
    iv: iv.toString(CryptoJS.enc.Hex),
    hmac: hmac,
  }
}

// Hàm giải mã dữ liệu

export function decryptData(encryptedData, iv, hmac, secretKey) {
  try {
    // Kiểm tra dữ liệu đầu vào
    if (!encryptedData || !iv || !hmac || !secretKey) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error('Missing decryption parameters:', {
          encryptedData,
          iv,
          hmac,
          secretKey,
        })
      }
      //throw new Error('Missing or invalid decryption parameters')
    }

    const key = CryptoJS.enc.Hex.parse(secretKey)
    const parsedIV = CryptoJS.enc.Hex.parse(iv)

    // Kiểm tra tính toàn vẹn của dữ liệu
    const recalculatedHmac = CryptoJS.HmacSHA256(encryptedData, key).toString()
    if (recalculatedHmac !== hmac) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error(
          'HMAC mismatch. Expected:',
          hmac,
          'Recalculated:',
          recalculatedHmac
        )
      }
      // throw new Error('Data integrity check failed')
    }

    // Giải mã dữ liệu
    const bytes = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: parsedIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })

    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    if (!decryptedData) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error('Decryption result is empty')
      }
      //throw new Error('Decryption result is empty');
    }

    return JSON.parse(decryptedData)
  } catch (error) {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
      console.error('Error during decryption:', error)
    }
    // Trả về một giá trị an toàn hoặc thông báo lỗi nhẹ trên production
    return { error: 'Decryption failed' }
  }
}

///NOTE ĐOẠN MÃ DÙNG MÃ HÓA CHUỖI CHO COOKIE, LOCAL,SESSION
export const encryptDataString = (data) => {
  try {
    // Tạo IV ngẫu nhiên
    const iv = CryptoJS.lib.WordArray.random(16)

    // Kiểm tra nếu `process.env.KEY_ENCRYPT` không tồn tại
    // eslint-disable-next-line no-undef
    if (!process.env.KEY_ENCRYPT) {
      throw new Error('Encryption is not defined in environment variables.')
    }

    // Mã hóa dữ liệu
    // eslint-disable-next-line no-undef
    const encrypted = CryptoJS.AES.encrypt(data, process.env.KEY_ENCRYPT, {
      iv: iv,
    }).toString()

    // Tạo HMAC để đảm bảo tính toàn vẹn
    const hmac = CryptoJS.HmacSHA256(
      encrypted,
      // eslint-disable-next-line no-undef
      process.env.KEY_ENCRYPT
    ).toString()

    // Trả về toàn bộ thông tin mã hóa dưới dạng chuỗi JSON
    return JSON.stringify({
      encryptedData: encrypted,
      iv: iv.toString(CryptoJS.enc.Hex),
      hmac: hmac,
    })
  } catch (error) {
    console.error('Error during encryption:', error.message)
    return null // Trả về null nếu xảy ra lỗi
  }
}

export const decryptDataString = (encryptedString) => {
  try {
    // Kiểm tra nếu chuỗi nhập vào là null hoặc undefined
    if (!encryptedString) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error('Encrypted string is null or undefined')
      }
      //cryptoUtils.js:132 Encrypted string is null or undefinedthrow new Error('Encrypted string is null or undefined')
    }

    // Parse lại đối tượng từ chuỗi JSON
    const { encryptedData, iv, hmac } = JSON.parse(encryptedString)

    if (!encryptedData || !iv || !hmac) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error('Invalid encrypted data structure')
      }
    }

    // Thực hiện giải mã (giả sử bạn dùng CryptoJS)
    // eslint-disable-next-line no-undef
    const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.KEY_ENCRYPT, {
      iv: CryptoJS.enc.Hex.parse(iv),
    })

    const decrypted = bytes.toString(CryptoJS.enc.Utf8)

    if (!decrypted) {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === 'development') {
        console.error('Decrypted string is empty')
      }
      //throw new Error('Decrypted string is empty')
    }

    return decrypted
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    // console.error('Error during decryption:', error.message)
    return '' // Trả về chuỗi trống nếu lỗi xảy ra
  }
}
