import React, { useState } from 'react'

import { useRouter } from 'next/router'
import toast, { Toaster } from 'react-hot-toast'
// import Link from 'next/link'
import { redirectToHomeIfAuthenticated } from '../../utils/auth'
import { decryptData } from '../../utils/cryptoUtils'
import Cookies from 'js-cookie'
import toastr from 'toastr'

import axios from 'axios'
// Trang đăng nhập
export async function getServerSideProps(context) {
  return redirectToHomeIfAuthenticated(context)
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const router = useRouter() // Initialize useRouter
  const [rememberMe, setRememberMe] = useState(false) // State cho checkbox

  const handleLogin = async () => {
    try {
      const responsemail = await axios.get(
        // eslint-disable-next-line no-undef
        `${process.env.NEXT_PUBLIC_SERVER_URL}/api/checkemailisbanned?email=${email}`,
        {
          headers: {
            'Content-Type': 'application/json',
            // eslint-disable-next-line no-undef
            'x-api-key': process.env.API_KEY_PROTECT,
          },
        }
      )
      // eslint-disable-next-line no-undef
      const secretKey = process.env.KEY_ENCRYPT // Khóa bí mật
      const { nuicao } = responsemail.data
      const { encryptedData, iv, hmac } = nuicao
      const decryptedPayload = decryptData(encryptedData, iv, hmac, secretKey)

      // Nếu `is_banned` là `true`, xóa cookie và chuyển hướng
      const is_banned = decryptedPayload.is_banned

      if (is_banned) {
        return toastr.error(
          'Tài khoản của bạn đã bị cấm. Vui lòng liên hệ quản trị viên.'
        )
      }

      const response = await axios.post('/api/login', {
        email,
        password,
      })

      sessionStorage.setItem('loggedIn', true)

      localStorage.setItem('userId', response.data.user.id)

      if (response.status === 200) {
        if (rememberMe) {
          Cookies.set('accessToken', response.data.accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })

          Cookies.set('refreshToken', response.data.refreshToken, {
            // eslint-disable-next-line no-undef
            expires: 7, //7days
            // httpOnly: true,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })
        } else {
          // Lưu token vào cookies
          Cookies.set('accessToken', response.data.accessToken, {
            expires: 1,
            sameSite: 'Lax',
            domain: '.birongonline.com', // Thay 'example.com'
            // eslint-disable-next-line no-undef
            secure: process.env.NODE_ENV === 'production',
          })
        }

        // localStorage.setItem('ewtxsdzcs', response.data.user.email)
        // document.cookie = 'isAuthenticated=true; path=/' // Lưu vào cookie

        // toast.success(
        //   'Đăng nhập thành công,hãy kiểm tra mã OTP gửi đến Telegram.'
        // )
        // // Đăng nhập thành công, gửi OTP qua Telegram
        // await axios.post('/api/send-otp', {
        //   email,
        //   userid: response.data.user.id,
        // })
        // // Redirect or navigate to another page after login
        // router.push('/verifyotp')

        toast.success('Đăng nhập thành công.')
        toastr.success(
          'Đăng nhập thành công!',
          `Xin chào ${response.data.user.username}!`
        )

        router.push('/admindashboard/homeindex')
      }
    } catch (error) {
      const err = error

      if (err.response) {
        const responseData = err.response?.data.error
        toast.error(responseData) // Hiển thị thông báo lỗi từ server
      }
    }
  }

  // Hàm xử lý khi nhấn phím Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <>
      <Toaster />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className=" d-flex align-items-center auth px-0 mt-5">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo text-center">
                    <img src="/logodark.png" alt="logo" />
                  </div>
                  <h4>Xin chào! hãy bắt đầu</h4>
                  <h6 className="font-weight-light">Tiếp tục đăng nhập.</h6>

                  <form className="pt-3">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Địa chỉ email"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Mật khẩu"
                        style={{ border: '1px solid #ccc' }}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown} // Lắng nghe sự kiện phím bấm
                      />
                    </div>
                    {/* Checkbox Ghi nhớ đăng nhập */}
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <label htmlFor="rememberMe" className="ml-2">
                        Ghi nhớ đăng nhập
                      </label>
                    </div>
                    <div className="mt-3">
                      <a
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        href="#"
                        onClick={handleLogin}
                      >
                        Đăng nhập
                      </a>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      {/* <Link
                        href="/forgotpassword"
                        className="auth-link text-primary"
                      >
                        Quên mật khẩu?
                      </Link> */}
                    </div>
                    {/* <div className="text-center mt-4 font-weight-light">
                      Chưa có tài khoản?{' '}
                      <Link href="/register" className="text-primary">
                        Tạo mới
                      </Link>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
