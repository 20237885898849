import './global.css'

import '../public/styles/assets/css/font-awesome.min.css'
import '../public/css/bootstrap.min.css'

import '../public/css/skydash.css'

import '../public/vendors/feather/feather.css'
import '../public/vendors/ti-icons/css/themify-icons.css'
import 'toastr/build/toastr.min.css'

import type { AppProps } from 'next/app'
import React, { useEffect } from 'react'
import Head from 'next/head'
import { parseCookies, destroyCookie } from 'nookies'
import axios, { AxiosError } from 'axios'

interface ErrorResponse {
  message: string
  error: string
}
function MyApp({ Component, pageProps }: AppProps) {
  const cookies = parseCookies()
  const token = cookies.accessToken || ''

  useEffect(() => {
    const checkIsBanned = async () => {
      try {
        // Gọi API để kiểm tra trạng thái
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_SERVER_URL}/api/checkisbanned`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
              'x-api-key': process.env.API_KEY_PROTECT as string,
            },
            withCredentials: true, // Đảm bảo gửi cookies kèm request
          }
        )
        // console.log(response.data.isBanned)
        // Nếu `is_banned` là `true`, xóa cookie và chuyển hướng
        const { is_banned } = response.data.isBanned
        if (is_banned) {
          alert('Tài khoản của bạn đã bị cấm. Vui lòng liên hệ quản trị viên.')
          destroyCookie(null, 'accessToken', { path: '/' })
          window.location.href = '/login' // Chuyển hướng về trang đăng nhập
        }
      } catch (error) {
        const err = error as AxiosError<ErrorResponse> // Sử dụng kiểu dữ liệu đã định nghĩa
        console.log('Lỗi khi kiểm tra trạng thái tài khoản:', err)
      }
    }

    if (token) {
      checkIsBanned()
    }
  }, [token])

  return (
    <React.Fragment>
      <Head>
        <title>NroNew - Bảng quản trị</title>
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  )
}

export default MyApp
